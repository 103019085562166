.z-container-main{
  position: relative;
  width: 100%;
  margin: 0px auto;
}
.z-container-default{
  width: 100%;
  margin: 0px auto;
  color: #FFFFFF;
}
.z-full{
  position: relative;
  text-align: center;
  width: 100%;
}
.z-head-logo-container{
  padding: 54px 0px 54px 0px;
}
.z-slideshow-slide{
  @include mui-fade(in, $duration: 2s);
}
.z-banking-header{
  position: relative;
  text-align: center;
  font-size: 58px;
  width: 624px;
  max-width: 100%;
  font-family: FuturaHeavy;
  margin: 0px auto;
}
.z-banking-button-container{
  position: relative;
  padding: 20px 0px 80px 0px;
}
.z-button-round-blue{
  position: relative;
  text-align: center;
  width: 224px;
  font-size: 18px;
  padding: 16px;
  border-radius: 28px;
  margin: 0px auto;
  background-color: #2546F5;
  cursor: pointer;
  transition: 0.2s;
}
.z-button-round-blue:hover{
  background-color: #2530F5;
}
.z-banking-highlight-image-container{
  width: 100%;
  overflow: hidden;
  height: 588px;
}
.absolute-images{
  overflow: visible;
}
.z-banking-highlight-image{
  width: 948px;
  max-width: 100%;
  margin: 0px auto;
}
.z-head-logo{
  position: relative;
  width: 108px;
  margin: 0px auto;
}
.z-solution-container{
  background-color: #141414;
}
.z-solution-header-container{
  padding: 120px 0px 60px 0px;
  font-family: FuturaMedium;
}
.z-solution-tabs-container{
  position: relative;
  text-align: center;
  width: 980px;
  padding: 0px 0px 100px 0px;
  max-width: 100%;
  margin: 0px auto;
}
.z-solution-tabs-content{
  position: relative;
  width: 100%;
  margin: 0px auto;
}
.z-solution-tab-content{
  padding: 0px 20px 0px 20px;
}
.z-solution-tab-card{
  position: relative;
  width: 100%;
  height: 180px;
  border: 0.2px solid;
  border-radius: 12px;
  border-color: rgba(83, 83, 83, 0.6);
  color: #898989;
  overflow: hidden;
}
.z-solution-account-balance-graph-image{
  position: absolute;
  bottom: -6%;
  left: -6%;
  width: 112%;
}

.z-solution-account-balance-content{
  width: 100%;
  text-align: left;
  padding: 8px 16px 8px 16px;
}
.z-solution-account-balance-header{
  font-size: 16px;
}
.z-solution-account-balance-header{
  font-size: 13px;
  color: #828282;
}
.z-solutions-tab-summary-header{
  position: relative;
  font-family: FuturaMedium;
  width: 100%;
  font-size: 16px;
  text-align: left;
}
.z-solutions-tab-summary-content{
  position: relative;
  width: 100%;
  color: #606060;
  padding-top: 4px;
  font-size: 16px;
  text-align: left;
}
.z-solutions-tab-summary-container{
  position: relative;
  padding: 30px 0px 0px 0px;
}
.z-self-article-container{
  position: relative;
  text-align: center;
  width: 100%;
  padding: 30px 0px 30px 0px;
  background: #1D1D1D;
}
.z-self-article-main{
  font-size: 18px;
  font-weight: 600;
  padding: 0px 0px 30px 0px;
}
.z-inline-image{
  position: relative;
  display: inline;
  height: 7px;
}
.z-doordarsh-article-main{
  position: relative;
  width: 878px;
  max-width: 100%;
  margin: 0px auto;
  font-size: 29px;
}
.z-doordarsh-article-writer{
  position: relative;
  padding: 30px 0px 0px 0px;
  font-size: 18px;
  font-weight: 500;
}
.z-doordarsh-article-background{
  position: absolute;
  width: 1800px;
  top: -640px;
  display: none;
}
.z-doordarsh-article-container{
  position: relative;
  width: 100%;
  background: url('/public/radial_circles.png');
  background-size: 110% auto;
  background-position: center;
  overflow: hidden;
}
.z-doordarsh-article-content{
  width: 100%;
  padding: 100px 0px 100px 0px;
  background-color: rgba(0, 0, 0, 0);
}
.z-footer-container{
  background: #000000;
  padding: 120px 140px 0px 140px;
}
.z-footer-logo{
  width: 76px;
}
.z-footer-summary-container{
  width: 480px;
  max-width: 100%;
  text-align: justify;
}
.z-footer-summary-content{
  padding: 0px 0px 20px 0px;
  font-size: 13px;
  color: #999999;
}
.z-footer-more-container{
  width: 100px;
  max-width: 100%;
  margin: 0px auto;
  text-align: left;
}
.z-footer-more-header{
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  padding: 0px 0px 15px 0px;
}
.z-footer-more-item{
  padding: 20px 0px 10px 0px;
  font-weight: 600;
  font-size: 16px;
}
.z-footer-contact-header{
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  padding: 0px 0px 0px 0px;
}
.z-footer-contact-container{
  width: 240px;
  text-align: left;
  max-width: 100%;
}
.z-footer-contact-details{
  font-size: 13px;
}
.z-footer-social-handle-image{
  display: inline;
  width: 30px;
  padding: 8px 10px 16px 0px;
}
.c-footer-special-links{
  font-size: 13px;
  padding: 20px 0px 60px 0px;
  width: 100%;
  text-align: left;
}
.c-footer-special-link{
  padding: 0px 60px 0px 0px;
}
.footer-hr{
  margin: 60px 0px 0px 0px;
}

.z-solution-send-money-button-container-0{
  width: 160px;
  margin: 0px auto;
  border: 1px solid;
  border-color: rgba(55, 55, 55, 0.9);
  padding: 10px 0px 10px 0px;
  border-radius: 25px;
  transition: 0.2s;
  cursor: pointer;
}
.z-solution-send-money-button-container-0:hover{
  background-color: rgba(55, 55, 55, 0.9);
}
.z-solution-send-money-button-container-1{
  width: 184px;
  margin: 0px auto;
  border: 1px solid;
  border-color: rgba(55, 55, 55, 0.9);
  border-radius: 35px;
  padding: 12px 0px 12px 0px;
}
.z-solution-send-money-button-container-2{
  width: 208px;
  margin: 0px auto;
  border: 1px solid;
  border-color: rgba(55, 55, 55, 0.4);
  border-radius: 55px;
  padding: 12px 0px 12px 0px;
}
.z-solution-send-money-button-container-3{
  width: 232px;
  margin: 28px auto;
  border: 1px solid;
  border-color: rgba(55, 55, 55, 0.1);
  border-radius: 65px;
  padding: 12px 0px 12px 0px;
}
.z-solution-send-money-icon{
  width: 25px;
  margin-top: -3px;
}
.z-solution-investment-icon{
  width: 30px;
  margin-top: -3px;
}
.z-solution-investment-item{
  border: 1px solid;
  border-color: rgba(55, 55, 55, 0.8);
  border-radius: 20px;
  padding: 14px;
  width: 280px;
  max-width: 90%;
  margin: 20px auto;
  cursor: pointer;
  transition: 0.2s;
}
.z-solution-investment-item:hover{
  background-color: rgba(55, 55, 55, 0.8);
}
.absolute-image-1{
  position: absolute;
  width: 100%;
  top: 240px;
  z-index: -10;
}
.z-self-article{
  max-width: 640px;
  margin: 0px auto;
}
.z-link{
  display: inline-block;
}
.z-slide-show-container{
  position: relative;
  width: 100%;
  height: 920px;
}
.z-slide-show-main{
  position: relative;
  width: 100%;
  z-index: -1;
}
.z-slide-show{
  width: 100%;
  height: 920px;
  white-space: nowrap;
  transition: ease 1500ms;
}
.z-slide-show-slide{
  display: inline-block;
  width: 100%;
}
.z-slide-show-pointers{
  position: absolute;
  width: 100%;
  background: rgba(0, 255, 255, 0);
  bottom: 0px;
  padding: 12px;
}
.z-slide-show-pointer{
  position: relative;
  width: 20px;
  height: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 0px 6px 0px 6px;
}
@media screen and (max-width: 410px) {
  .z-footer-container{
    background: #000000;
    padding: 18px 21px 0px 21px;
  }
  .c-footer-special-link{
    display: block;
  }
  .z-doordarsh-article-content{
    padding: 21px;
  }
  .z-self-article-main{
    padding: 0px 21px 30px 21px;
  }
  .z-solution-header-container{
    padding: 120px 21px 60px 21px;
  }
  .z-banking-highlight-image-container{
    height: 240px;
  }
  .z-banking-header{
    font-size: 38px;
  }
  .z-banking-highlight-image{
    width: 80%;
  }
  .z-slide-show-container{
    height: 440px;
  }
  .z-doordarsh-article-container{
    background-size: 140% auto;
  }
}