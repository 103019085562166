body {
  width: 100% !important;
  margin: 0px !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
  box-sizing: border-box !important;
  background-color: #000000 !important;
  font-family: futur;
}
*, *:before, *:after {
  box-sizing: inherit;
}
#root{
  width:100% !important;
  margin: 0px !important;
  overflow-x: hidden !important;
  scroll-behavior:smooth;

}

@font-face {
  font-family: 'futur';
  src: local('futur'), url(./fonts/futura/futur.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaBold';
  src: local('FuturaBoldfont'), url(./fonts/futura/FuturaBoldfont.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaHeavy';
  src: local('FuturaBoldfont'), url(./fonts/futura/FuturaHeavyfont.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaMedium';
  src: local('futuramediumbt'), url(./fonts/futura/futuramediumbt.ttf) format('truetype');
}
a{
  color: unset !important;
  text-decoration: none !important;
}
a:hover{
  color: unset !important;
  text-decoration: none !important;
}